import React from "react";
import HomePage from "./pages/home.page";

/**Portfolio-erick - version 6.06 - App  js file -
 * Features:
 * 
 *      --> Refactoring layout.
 * 
 *      --> Pending to set up again routing
 * 
 * Notes: This file is gonna be changed later.
 */

function App() {
  return (
    <HomePage />
  );
}

export default App;
